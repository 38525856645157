import React from 'react'

const ContactForm = () => (
  <form
    className="contact-form"
    name="contact-form"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    method="POST"
    action="#"
  >
    <div className="contact-form--field">
      <label className="contact-form--label">
        Name
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="name"
        placeholder="Enter your name"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Email*
      </label>

      <input
        className="contact-form--input"
        type="email"
        name="email"
        placeholder="Enter your email"
        required
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Phone Number
      </label>

      <input
        className="contact-form--input"
        type="tel"
        name="tel"
        placeholder="Phone Number"
      />
    </div>

    <div className="contact-form--field">
      <label className="contact-form--label">
        Company
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="company"
        placeholder="Enter your company name"
      />
    </div>

    <div className="contact-form--field contact-form--field__fullWidth">
      <label className="contact-form--label">
        ASI/Sage/DC/PPAI Number
      </label>

      <input
        className="contact-form--input"
        type="text"
        name="number"
        placeholder="Enter your number"
      />
    </div>

    <div className="contact-form--field contact-form--field__fullWidth">
      <label className="contact-form--label">
        Message
      </label>

      <textarea
        className="contact-form--input"
        name="message"
        placeholder="Enter your message"
      />
    </div>

    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact-form" />

    <button
      className="contact-form--button"
      type="submit"
    >
      Send
    </button>
  </form>
)

export default ContactForm