import React from 'react'
import { graphql } from 'gatsby'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/contactForm'
import SampleRequestForm from '../components/sampleRequestForm'
import QuoteForm from '../components/quoteForm'

const ContactPage = ({ data: { Colors, Bags }}) => {
  const colorsList = Colors.nodes.map(node => {
    return {
      value: node.name,
      label: node.name
    }
  });
  const bagsList = Bags.nodes.map(node => {
    return {
      value: node.name,
      label: node.name
    }
  })
  return (
    <Layout>
      <SEO title="Contact" />

      <div className="contact-container">
        <Tabs
          className="contact-tabs"
          forceRenderTabPanel={ true }
        >
          <TabList className="contact-tab-list">
            <Tab
              className="contact-tab"
              selectedClassName="contact-tab__selected"
              disabledClassName="contact-tab__disabled"
            >
              Contact
            </Tab>

            <Tab
              className="contact-tab"
              selectedClassName="contact-tab__selected"
              disabledClassName="contact-tab__disabled"
            >
              Sample
            </Tab>

            <Tab
              className="contact-tab"
              selectedClassName="contact-tab__selected"
              disabledClassName="contact-tab__disabled"
            >
              Quote
            </Tab>
          </TabList>

          <TabPanel
            className="contact-tab-panel"
            selectedClassName="contact-tab-panel__selected"
          >
            <div className="contact-info">
              Please fill out the form and we will get back to you as soon as possible
            </div>

            <ContactForm />
          </TabPanel>

          <TabPanel
            className="contact-tab-panel"
            selectedClassName="contact-tab-panel__selected"
          >
            <div className="contact-info contact-info__centered">
              Samples ship out free via USPS
            </div>

            <SampleRequestForm
              colors={ colorsList }
              bags={ bagsList }
            />
          </TabPanel>

          <TabPanel
            className="contact-tab-panel"
            selectedClassName="contact-tab-panel__selected"
          >
            <div className="contact-info">
              Please fill out the form and we will get back to you as soon as possible
            </div>

            <QuoteForm
              colors={ colorsList }
              bags={ bagsList }
            />
          </TabPanel>
        </Tabs>
      </div>

    </Layout>
  )
}

export const query = graphql`
  {
    Colors: allColorsYaml {
      nodes {
        name
      }
    }

    Bags: allBagsYaml {
      nodes {
        name
      }
    }
  }
`

export default ContactPage
